import { useNavigate, useParams } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyIcon from "../assets/images/copy.svg";
import mailIcon from "../assets/images/mail.svg";
import phoneIcon from "../assets/images/phone.svg";
import emptyStarIcon from "../assets/images/empty-star.svg";
import fullStarIcon from "../assets/images/full-star.svg";
import webIcon from "../assets/images/web.svg";
// import twitterIcon from "../assets/images/twitter.svg";
import arrowDownIcon from "../assets/images/arrow-down.svg";
// import instagramIcon from "../assets/images/instagram.svg";
// import whatsappIcon from "../assets/images/whatsapp-icon.svg";
import twitterIcon from '../assets/images/social-twitter.svg';
import instagramIcon from '../assets/images/social-instagram.svg';
import whatsappIcon from '../assets/images/social-whatsapp.svg';

import prevIcon from '../assets/images/shop-arrow-left.svg';
import nextIcon from '../assets/images/shop-arrow-right.svg';
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import toast from "react-hot-toast";
import TrackingHeader from "../components/TrackingHeader";
import TrackingShare from "../components/TrackingShare";
import { useDispatch, useSelector } from "react-redux";
import TrackingProgressBar from "../components/TrackingProgressBar";
import { submitCustomerFeedback, trackOrder } from "../redux/actions/track";
import Rating from "react-rating";
import TrackingFooter from "../components/TrackingFooter";
import TrackingFeedbackModal from "../components/TrackingFeedbackModal";
import TrackingDelayedMessage from "../components/TrackingDelayedMessage";
import { Carousel } from "antd";

const MainTrackingTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tracking_data, loading, branding_data } = useSelector(
    (state) => state.shipment
  );
  const slider = useRef(null);
  const { id } = useParams();
  const [comment, setComment] = useState("Satisfactory");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [trackingError, setTrackingError] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isGettingData, setIsGettingData] = useState(false);
  const [trackingStatus, setTrackingStatus] = useState("");
  useEffect(() => {
    if (tracking_data === null || tracking_data === undefined) {
      setTrackingError(null);
      dispatch(trackOrder(id, handleError));
    } else {
      const arr = tracking_data?.package_status.filter(
        (item) => item.datetime !== null
      );

      const latest = arr.sort((a, b) =>
        moment(b.datetime) > moment(a.datetime)
          ? 1
          : moment(b.datetime) < moment(a.datetime)
            ? -1
            : 0
      );

      setTrackingStatus(latest[0]);
    }
  }, [tracking_data]);

  const handleCopy = (type) => {
    toast.success(`Copied ${type} Successfully!`);
  };

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleRatingClose = () => {
    setRatingValue(0);
    setIsModalOpen(false);
  };

  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value.trim());
  };

  const replaceEmoji = (value) => {
    return value?.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
  };

  const handleRatingSubmit = () => {
    const editedComment = replaceEmoji(comment);
    const data = {
      score: ratingValue,
      comment: editedComment,
    };
    dispatch(submitCustomerFeedback(id, data, handleRatingResponse));
  };

  const handleRatingResponse = () => {
    dispatch(trackOrder(id, handleError));
    setIsModalOpen(false);
    setRatingValue(0);
  };

  const handleSubmit = () => {
    setTrackingError(null);
    setIsGettingData(true);
    dispatch(trackOrder(value.trim(), handleError));
  };
  const handleError = (id, status) => {
    if (status === "error") {
      setTrackingError(true);
      setIsGettingData(false);
    } else {
      navigate(`/shipment/${id}`);
      setTrackingError(false);
      setIsGettingData(false);
    }
  };

  const handleRatingChange = (value) => {
    setRatingValue(value);
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
  };

  const handleTextareaChange = (event) => {
    setComment(event.target.value);
  };

  const replaceApostrophe = (str) => {
    return str?.replace("&amp;#x27;", "'")?.replace("&#x27;", "'");
  };

  return (
    <div>
      <TrackingFeedbackModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleRatingClose={handleRatingClose}
        handleRatingChange={handleRatingChange}
        ratingValue={ratingValue}
        handleTextareaChange={handleTextareaChange}
        comment={comment}
        handleRatingSubmit={handleRatingSubmit}
      />

      {!loading ? (
        <div className="tracking-body">
          {!trackingError ? (
            <div className="tracking-container">
              <TrackingHeader
                title={replaceApostrophe(tracking_data?.business?.business_name)}
                hasTitle={true}
                copy={true}
                data={tracking_data}
                mode={tracking_data?.mode}
                brandingData={branding_data}
              />

              {tracking_data?.delay_info?.message !== undefined && (
                <TrackingDelayedMessage
                  message={tracking_data?.delay_info?.message}
                />
              )}
              <div className="tracking-container-status">
                <div className="tracking-container-status-text">
                  <div className="tracking-container-status-estimate">
                    <h1>{trackingStatus?.label}</h1>

                    <>
                      <h6>
                        {" "}
                        {trackingStatus?.status === "cancelled"
                          ? "Cancelled on"
                          : trackingStatus?.status !== "completed"
                            ? "Estimated Delivery Date"
                            : "Delivered on"}
                      </h6>
                      <p>
                        {trackingStatus?.status !== "completed" && trackingStatus?.status !== 'cancelled' ? (
                          <>
                            {" "}
                            {moment(tracking_data?.delivery_eta_time).format(
                              "dddd, Do MMMM, YYYY"
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            {moment(trackingStatus?.datetime).format(
                              "dddd, Do MMMM, YYYY"
                            )}
                          </>
                        )}
                      </p>
                    </>
                  </div>
                  <div className="tracking-container-status-address">
                    <h5>Delivery address</h5>
                    <h6>
                      {tracking_data?.ship_to?.city},{" "}
                      {tracking_data?.ship_to?.state},{" "}
                      {tracking_data?.ship_to?.country}
                    </h6>
                    <p>*Address shortened for privacy.</p>
                  </div>
                </div>
                <TrackingProgressBar
                  status={tracking_data?.status}
                  data={tracking_data?.package_status}
                  brandingData={branding_data}
                />
              </div>

              {tracking_data?.is_customer_rateable &&
                !tracking_data?.is_customer_rated && (
                  <div className="tracking-container-feedback">
                    <div className="tracking-container-feedback-left">
                      <h4>Customer feedback</h4>
                      <p>Help us improve your experience</p>
                    </div>
                    <div className="tracking-container-feedback-right">
                      <p>How was your experience?</p>

                      <Rating
                        emptySymbol={
                          <img src={emptyStarIcon} alt="empty-star" />
                        }
                        fullSymbol={<img src={fullStarIcon} alt="full-star" />}
                        onChange={handleRatingChange}
                      />
                    </div>
                  </div>
                )}

              <div className={(tracking_data?.business?.whatsapp_link !== null || tracking_data?.business?.website_url !== null || tracking_data?.business?.instagram_url !== null || tracking_data?.business?.twitter_url !== null) ? "tracking-content-flex" : 'tracking-content-flex column'}>
                {/* <TrackingShare
                  name="share-bg tracking-share-card"
                  url={tracking_data?.tracking_url}
                  text="Share a WOW-worthy experience!"
                /> */}
                <div className="tracking-content-history">
                  <p>Tracking history</p>
                  <div
                    className={
                      tracking_data?.events?.length > 4 && showMore
                        ? "tracking-content-history-list"
                        : "tracking-content-history-list hidden"
                    }
                  >
                    {tracking_data?.events !== null ? (
                      <>
                        {tracking_data?.events?.length > 4 && showMore
                          ? tracking_data?.events.map((item, index) => (
                            <div
                              key={"events" + index}
                              className="tracking-content-history-list-item"
                            >
                              <div className="tracking-content-history-list-item-time">
                                <p className="history-date">
                                  {moment(item.captured).format(
                                    "MMM DD, YYYY"
                                  )}
                                </p>
                                <p className="history-time">
                                  {moment(item.captured).format("H:mm A")}
                                </p>
                              </div>
                              <div className="tracking-content-history-list-item-message">
                                <p className="history-location">
                                  {item.message}
                                </p>
                                <p className="history-message">
                                  {item.location}
                                </p>
                              </div>
                            </div>
                          ))
                          : tracking_data?.events
                            .slice(0, 4)
                            .map((item, index) => (
                              <div
                                key={"events" + index}
                                className="tracking-content-history-list-item"
                              >
                                <div className="tracking-content-history-list-item-time">
                                  <p className="history-date">
                                    {moment(item.captured).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </p>
                                  <p className="history-time">
                                    {moment(item.captured).format("H:mm A")}
                                  </p>
                                </div>
                                <div className="tracking-content-history-list-item-message">
                                  <p className="history-location">
                                    {item.message}
                                  </p>
                                  <p className="history-message">
                                    {item.location}
                                  </p>
                                </div>
                              </div>
                            ))}
                      </>
                    ) : (
                      <>
                        {tracking_data?.package_status.filter(
                          (item) => item.datetime !== null
                        )?.length > 4 && showMore
                          ? tracking_data?.package_status
                            .filter((item) => item.datetime !== null).sort((a, b) =>
                              moment(b.datetime) > moment(a.datetime)
                                ? 1
                                : moment(b.datetime) < moment(a.datetime)
                                  ? -1
                                  : 0
                            ).map((item, index) => (
                              <div
                                key={"package_status" + index}
                                className="tracking-content-history-list-item"
                              >
                                <div className="tracking-content-history-list-item-time package-status-time">
                                  <p className="history-date">
                                    {moment(item.datetime).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </p>
                                  <p className="history-time">
                                    {moment(item.datetime).format("H:mm A")}
                                  </p>
                                </div>
                                <div className="tracking-content-history-list-item-message">
                                  <p className="history-location package-status-label">
                                    {item.label}
                                  </p>
                                </div>
                              </div>
                            ))
                          : tracking_data?.package_status
                            .filter((item) => item.datetime !== null).sort((a, b) =>
                              moment(b.datetime) > moment(a.datetime)
                                ? 1
                                : moment(b.datetime) < moment(a.datetime)
                                  ? -1
                                  : 0
                            ).slice(0, 4).map((item, index) => (
                              <div
                                key={"package_status" + index}
                                className="tracking-content-history-list-item"
                              >
                                <div className="tracking-content-history-list-item-time package-status-time">
                                  <p className="history-date">
                                    {moment(item.datetime).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </p>
                                  <p className="history-time">
                                    {moment(item.datetime).format("H:mm A")}
                                  </p>
                                </div>
                                <div className="tracking-content-history-list-item-message">
                                  <p className="history-location package-status-label">
                                    {item.label}
                                  </p>
                                </div>
                              </div>
                            ))}
                      </>
                    )}

                    <>
                      {tracking_data?.events !== null ? (
                        <>
                          {tracking_data?.events?.length > 4 && !showMore ? (
                            <div className="tracking-history-more">
                              <p onClick={handleShowMore}>
                                View more
                                <span>
                                  <img
                                    src={arrowDownIcon}
                                    alt="arrow-down-icon"
                                  />
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div
                              className={
                                tracking_data?.events?.length > 4
                                  ? "tracking-history-less"
                                  : "tracking-history-less hidden"
                              }
                            >
                              <p onClick={() => setShowMore(false)}>
                                View less
                                <span>
                                  <img
                                    src={arrowDownIcon}
                                    alt="arrow-up-icon"
                                  />
                                </span>
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {tracking_data?.package_status.filter(
                            (item) => item.datetime !== null
                          )?.length > 4 && !showMore ? (
                            <div className="tracking-history-more">
                              <p onClick={handleShowMore}>
                                View more
                                <span>
                                  <img
                                    src={arrowDownIcon}
                                    alt="arrow-down-icon"
                                  />
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div
                              className={
                                tracking_data?.package_status.filter(
                                  (item) => item.datetime !== null
                                )?.length > 4
                                  ? "tracking-history-less"
                                  : "tracking-history-less hidden"
                              }
                            >
                              <p onClick={() => setShowMore(false)}>
                                View less
                                <span>
                                  <img
                                    src={arrowDownIcon}
                                    alt="arrow-up-icon"
                                  />
                                </span>
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </div>

                  <div className="tracking-content-history-list bg">
                    {tracking_data?.events !== null
                      ? tracking_data?.events.map((item, index) => (
                        <div
                          key={"events" + index}
                          className="tracking-content-history-list-item"
                        >
                          <div className="tracking-content-history-list-item-time">
                            <p className="history-date">
                              {moment(item.captured).format("MMM DD, YYYY")}
                            </p>
                            <p className="history-time">
                              {moment(item.captured).format("H:mm A")}
                            </p>
                          </div>
                          <div className="tracking-content-history-list-item-message">
                            <p className="history-location">{item.message}</p>
                            <p className="history-message">{item.location}</p>
                          </div>
                        </div>
                      ))
                      : tracking_data?.package_status
                        .filter((item) => item.datetime !== null).sort((a, b) =>
                          moment(b.datetime) > moment(a.datetime)
                            ? 1
                            : moment(b.datetime) < moment(a.datetime)
                              ? -1
                              : 0
                        ).map((item, index) => (
                          <div
                            key={"package_status" + index}
                            className="tracking-content-history-list-item"
                          >
                            <div className="tracking-content-history-list-item-time package-status-time">
                              <p className="history-date">
                                {moment(item.datetime).format("MMM DD, YYYY")}
                              </p>
                              <p className="history-time">
                                {moment(item.datetime).format("H:mm A")}
                              </p>
                            </div>
                            <div className="tracking-content-history-list-item-message">
                              <p className="history-location package-status-label">
                                {item.label}
                              </p>
                            </div>
                          </div>
                        ))}
                  </div>

                  <div className="tracking-content-footer">
                    <div className="tracking-courier-info">
                      <div>
                        <img
                          src={tracking_data?.courier?.service_icon}
                          alt="courier-icon"
                        />
                        <p className="courier-name">
                          {tracking_data?.courier?.name}
                        </p>
                      </div>

                      {tracking_data?.tracking_code !== null && (
                        <p className="courier-id">
                          <span className="courier-code">
                            {tracking_data?.tracking_message}
                          </span>
                          <CopyToClipboard
                            text={tracking_data?.tracking_message}
                            onCopy={() => handleCopy("Tracking ID")}
                          >
                            <span className="pointer">
                              <img
                                className="pointer"
                                src={copyIcon}
                                alt="copy"
                              />
                            </span>
                          </CopyToClipboard>
                        </p>
                      )}
                    </div>
                    <div className="tracking-content-contact-flex">
                      <img
                        className="pointer"
                        onClick={() =>
                          (window.location.href = `tel:${tracking_data?.courier?.phone}`)
                        }
                        src={phoneIcon}
                        alt="phone-icon"
                      />
                      <img
                        className="pointer"
                        onClick={() =>
                          (window.location.href = `mailto:${tracking_data?.courier?.email}`)
                        }
                        src={mailIcon}
                        alt="email-icon"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="tracking-items-flex">
                  
                                   {(tracking_data?.business?.whatsapp_link !== null || tracking_data?.business?.website_url !== null || tracking_data?.business?.instagram_url !== null|| tracking_data?.business?.twitter_url !== null) &&   <div className="tracking-items share-bg bg-image">
                    <h6>
                      Contact{" "}
                      {tracking_data?.business?.business_name
                        .replace("&amp;#x27;", "'")
                        .replace("&#x27;", "'")}{" "}
                      for support
                    </h6>
 <div className="tracking-items-socials">
                      {tracking_data?.business?.whatsapp_link !== null && (
                        <a
                          href={tracking_data?.business?.whatsapp_link}
                          target="_blank"
                          rel="noreferrer"
                          className="pointer"
                        >
                          <span>
                            <img src={whatsappIcon} alt="web-icon" />
                          </span>
                        </a>
                      )}
                      {tracking_data?.business?.website_url !== null && (
                        <a
                          href={tracking_data?.business?.website_url}
                          target="_blank"
                          rel="noreferrer"
                          className="tracking-container-header-socials-link"
                        >
                          <span>
                            <img src={webIcon} alt="web-icon" />
                          </span>
                        </a>
                      )}
                      {tracking_data?.business?.twitter_url !== null && (
                        <a
                          href={tracking_data?.business?.twitter_url}
                          target="_blank"
                          rel="noreferrer"
                          className="tracking-container-header-socials-link"
                        >
                          <span>
                            <img src={twitterIcon} alt="twitter-icon" />
                          </span>
                        </a>
                      )}
                      {tracking_data?.business?.instagram_url !== null && (
                        <a
                          href={tracking_data?.business?.instagram_url}
                          target="_blank"
                          rel="noreferrer"
                          className="tracking-container-header-socials-link"
                        >
                          <span>
                            <img src={instagramIcon} alt="instagram-icon" />
                          </span>
                        </a>
                      )}
                    </div>
                  </div>}
                </div> */}
              </div>

              {branding_data?.banner?.brand_banner_url !== undefined && branding_data?.banner?.brand_banner_url !== null && <div
                className='brand-banner-tracking'
              >
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    backgroundImage: `url(${branding_data?.banner?.brand_banner_url})`
                  }}
                  className="banner-container"
                  href={branding_data?.banner?.brand_banner_link_url}>
                  {/* <img src={branding_data?.banner?.brand_banner_url} /> */}
                </a>
                <div>
                </div>
              </div>}

              {branding_data !== null &&
                (branding_data?.product_links?.product_image_url_1 !== null ||
                  branding_data?.product_links?.product_image_url_2 !== null ||
                  branding_data?.product_links?.product_image_url_3 !==
                  null) &&


                <div className='branding-container-tracking-right-laptop-body-content-shop preview-shop'>
                  <div className='preview-shop-message'>
                    <p className='branding-container-tracking-right-laptop-body-content-shop-title'>
                      More products

                      you might love

                    </p>
                    <div className=''>
                      <div onClick={() => slider.current.prev()}>
                        <img src={prevIcon} alt='prev' />
                      </div>
                      <div onClick={() => slider.current.next()}>
                        <img src={nextIcon} alt='next' />
                      </div>
                    </div>
                  </div>


                  <div className='branding-container-tracking-right-view-tabs'>

                    <Carousel infinite={false} ref={slider} dots={false} slidesToShow={3.5} slidesToScroll={1}>

                      {branding_data?.product_links?.product_image_url_1 !== null &&
                        <a href={branding_data?.product_links?.product_link_1}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>

                            <img
                              src={
                                branding_data?.product_links?.product_image_url_1

                              }
                            />
                          </div>
                        </a>
                      }
                      {branding_data?.product_links?.product_image_url_2 !== null &&

                        <a href={branding_data?.product_links?.product_link_2}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>
                            <img
                              src={
                                branding_data?.product_links?.product_image_url_2
                              }
                            />
                          </div>
                        </a>
                      }
                      {branding_data?.product_links?.product_image_url_3 !== null &&
                        <a href={branding_data?.product_links?.product_link_3}

                          target='_blank'
                          rel='noopener noreferrer'>
                          <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>
                            <img
                              src={
                                branding_data?.product_links?.product_image_url_3
                              }
                            />
                          </div></a>}
                      {branding_data?.product_links?.product_image_url_1 !== null &&
                        <a href={branding_data?.product_links?.product_link_1}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>

                            <img
                              src={
                                branding_data?.product_links?.product_image_url_1

                              }
                            />
                          </div>
                        </a>
                      }
                      {branding_data?.product_links?.product_image_url_2 !== null &&

                        <a href={branding_data?.product_links?.product_link_2}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>
                            <img
                              src={
                                branding_data?.product_links?.product_image_url_2
                              }
                            />
                          </div>
                        </a>
                      }
                      {branding_data?.product_links?.product_image_url_3 !== null &&
                        <a href={branding_data?.product_links?.product_link_3}

                          target='_blank'
                          rel='noopener noreferrer'>
                          <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>
                            <img
                              src={
                                branding_data?.product_links?.product_image_url_3
                              }
                            />
                          </div></a>}                      {branding_data?.product_links?.product_image_url_1 !== null &&
                            <a href={branding_data?.product_links?.product_link_1}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>

                                <img
                                  src={
                                    branding_data?.product_links?.product_image_url_1

                                  }
                                />
                              </div>
                            </a>
                      }
                      {branding_data?.product_links?.product_image_url_2 !== null &&

                        <a href={branding_data?.product_links?.product_link_2}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>
                            <img
                              src={
                                branding_data?.product_links?.product_image_url_2
                              }
                            />
                          </div>
                        </a>
                      }
                      {branding_data?.product_links?.product_image_url_3 !== null &&
                        <a href={branding_data?.product_links?.product_link_3}

                          target='_blank'
                          rel='noopener noreferrer'>
                          <div className='branding-container-tracking-right-laptop-body-content-shop-flex-item tracking-shop-item'>
                            <img
                              src={
                                branding_data?.product_links?.product_image_url_3
                              }
                            />
                          </div></a>}
                    </Carousel>
                  </div>


                </div>}


              <div className={(tracking_data?.business?.whatsapp_link !== null || tracking_data?.business?.website_url !== null || tracking_data?.business?.instagram_url !== null || tracking_data?.business?.twitter_url !== null) ? 'tracking-content-social' : 'tracking-content-social flex'}>
                <p>Thanks for supporting our business - clearly, you&apos;ve got a great taste! 🤍</p>
                {/* <div>
            <div >
                <img src={whatsappIcon} alt='next'/>
              </div>
              <div >
                <img src={whatsappIcon} alt='next'/>
              </div>
              <div >
                <img src={twitterIcon} alt='next'/>
              </div>
              <div >
                <img src={instagramIcon} alt='next'/>
                </div>
                </div> */}

                {(tracking_data?.business?.whatsapp_link !== null || tracking_data?.business?.website_url !== null || tracking_data?.business?.instagram_url !== null || tracking_data?.business?.twitter_url !== null) && <div className="tracking-items-socials">
                  {tracking_data?.business?.whatsapp_link !== null && (
                    <a
                      href={tracking_data?.business?.whatsapp_link}
                      target="_blank"
                      rel="noreferrer"
                      className="pointer"
                    >
                      <span>
                        <img src={whatsappIcon} alt="web-icon" />
                      </span>
                    </a>
                  )}
                  {tracking_data?.business?.website_url !== null && (
                    <a
                      href={tracking_data?.business?.website_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <img src={webIcon} alt="web-icon" />
                      </span>
                    </a>
                  )}
                  {tracking_data?.business?.twitter_url !== null && (
                    <a
                      href={tracking_data?.business?.twitter_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <img src={twitterIcon} alt="twitter-icon" />
                      </span>
                    </a>
                  )}
                  {tracking_data?.business?.instagram_url !== null && (
                    <a
                      href={tracking_data?.business?.instagram_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        <img src={instagramIcon} alt="instagram-icon" />
                      </span>
                    </a>
                  )}
                </div>}
              </div>
              <TrackingFooter />
            </div>
          ) : (
            <div className="tracking-container tracking-error-container">
              {/* <div className='tracking-container-header tracking-center'>
                <div className='tracking-container-header-logo'>
                  <h1 className='tracking-container-header-title'>
                    Shipbubble
                  </h1>
                </div>
              </div> */}
              <TrackingHeader hasTitle={false} />
              <div className="tracking-page-error">
                <h1>Tracking page not found</h1>
                <p>
                  It appears this link is broken. Enter tracking code below or
                  go home
                </p>
                <div className="tracking-content-form">
                  <input
                    type="text"
                    placeholder="Enter Tracking Code eg. SB-252252525"
                    value={value}
                    onChange={(e) => handleChange(e)}
                  />
                  <button
                    type="button"
                    disabled={value.trim() === "" || isGettingData}
                    onClick={handleSubmit}
                  >
                    {isGettingData ? (
                      <PulseLoader color="white" size={8} />
                    ) : (
                      "Track"
                    )}
                  </button>
                </div>
                <div className="tracking-choice">
                  <div></div>
                  <p>or</p>
                  <div></div>
                </div>
                <div className="tracking-page-error-button ">
                  <button onClick={() => navigate("/")}>Go back Home</button>
                </div>
              </div>
              <TrackingShare
                name="share-bg tracking-share-card"
                url="https://www.shipbubble.com/"
                text="Share a WOW-worthy experience!"
              />
              <TrackingFooter />
            </div>
          )}
        </div>
      ) : (
        <div className="tracking-body loading-body">
          <div className="tracking-container">
            <Skeleton
              height={120}
              style={{
                borderRadius: "16px",
              }}
              count={1}
              containerClassName={"react-loading-skeleton-container"}
            />

            <Skeleton
              height={235}
              style={{
                borderRadius: "16px",
                marginBottom: "16px",
                marginTop: "16px",
              }}
            />
            <div className="loading-flex">
              <Skeleton
                height={125}
                style={{
                  borderRadius: "16px",
                  marginBottom: "16px",
                }}
                containerClassName={"loading-share"}
              />

              <Skeleton
                height={385}
                style={{
                  borderRadius: "16px",
                  marginBottom: "16px",
                }}
                containerClassName={"loading-history"}
              />

              <div className="loading-flex-right">
                <Skeleton
                  height={145}
                  style={{
                    borderRadius: "16px",
                  }}
                  containerClassName={"loading-item"}
                />
                <Skeleton
                  height={125}
                  style={{
                    borderRadius: "16px",
                    marginBottom: "16px",
                  }}
                  containerClassName={"loading-share"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainTrackingTemplate;
