import React from 'react';
import closeIcon from '../assets/images/close-icon.svg';
import emptyStarIcon from '../assets/images/empty-star.svg';
import fullStarIcon from '../assets/images/full-star.svg';
import {PulseLoader} from 'react-spinners';
import {useSelector} from 'react-redux';
import ReactModal from 'react-modal';
import Rating from 'react-rating';

const TrackingFeedbackModal = ({
  isModalOpen,
  setIsModalOpen,
  handleRatingClose,
  handleRatingChange,
  ratingValue,
  handleTextareaChange,
  comment,
  handleRatingSubmit,
}) => {
  const {is_submitting} = useSelector((state) => state.shipment);
  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      shouldCloseOnOverlayClick={true}
      destroyOnClose={true}
      overlayClassName='discard-overlay'
      className='discard-content'
      style={{
        overlay: {
          background: 'rgba(41, 45, 50, 0.28)',
          overflow: 'auto',
          minHeight: '100vh',
          padding: '24px 40px',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        content: {
          background: 'transparent',
          border: 'none',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          minHeight: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      }}
    >
      <div className='rating-container'>
        <div className='rating-header'>
          <h1>Customer feedback</h1>
          <img
            className='pointer'
            src={closeIcon}
            onClick={handleRatingClose}
            alt='close-icon'
          />
        </div>
        <div className='rating-content'>
          <p>How was your experience?</p>
          <Rating
            emptySymbol={<img src={emptyStarIcon} alt='empty-icon' />}
            fullSymbol={<img src={fullStarIcon} alt='full-icon' />}
            onChange={handleRatingChange}
            initialRating={ratingValue}
          />
        </div>
        <hr className='rating-line' />

        <div className='tracking-textarea'>
          <label>Any Additional Feedback or Suggestions?</label>
          <textarea
            value={comment}
            placeholder='Write something...'
            onChange={handleTextareaChange}
          ></textarea>
        </div>

        <button
          className='rating-button'
          disabled={comment?.trim() === ''}
          type='button'
          onClick={handleRatingSubmit}
        >
          {is_submitting ? (
            <PulseLoader color='white' size={8} />
          ) : (
            'Share feedback'
          )}
        </button>
      </div>
    </ReactModal>
  );
};

export default TrackingFeedbackModal;
