import Tracking from './pages/Tracking';
// import TrackingPage from './pages/TrackingPage';
import MainTrackingTemplate from './templates/MainTrackingTemplate';

export const routes = [
  {
    id: 1,
    path: '/',
    component: Tracking,
  },
  {
    id: 2,
    path: '/shipment/:id',
    component: MainTrackingTemplate,
  },
  {
    id: 3,
    path: '*',
    component: Tracking,
  },
];
