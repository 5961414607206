import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {routes} from './routes';
import 'rsuite/dist/rsuite.min.css';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {Object.values(routes).map((el) => (
          <Route exact path={el.path} key={el.id} element={<el.component />} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
