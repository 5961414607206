import axios from 'axios';
const baseURL = process.env.REACT_APP_BASE_URL;

export const trackShippingOrder = async (id) => {
  const response = await axios.get(`${baseURL}/shipments/tracking/${id}`);
  return response.data;
};

export const submitFeedback = async (id, data) => {
  const response = await axios.post(
    `${baseURL}/shipments/rating/${id}/customer/feedback`,
    data
  );
  return response.data;
};
