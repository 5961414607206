import {PulseLoader} from 'react-spinners';
import {useNavigate} from 'react-router';
import {useState} from 'react';
import TrackingHeader from '../components/TrackingHeader';
import TrackingShare from '../components/TrackingShare';
import TrackingFooter from '../components/TrackingFooter';
import {useDispatch, useSelector} from 'react-redux';
import {trackOrder} from '../redux/actions/track';
import toast from 'react-hot-toast';
import logoIcon from '../assets/images/logo-bg.svg';
import React from 'react';

const Tracking = () => {
  const dispatch = useDispatch();
  const {loading} = useSelector((state) => state.shipment);
  const navigate = useNavigate();

  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value.trim());
  };

  const handleSubmit = () => {
    dispatch(trackOrder(value.trim(), handleSuccess));
  };

  const handleSuccess = (id, status, error) => {
    if (status === 'error') {
      if (error?.response?.data === 'Unauthorized') {
        toast.error(error?.response?.data);
      }
    } else {
      navigate(`/shipment/${id}`);
    }
  };



  return (
    <>
      <div className='tracking-body'>
        <div className='tracking-container home-container'>
          <TrackingHeader image={logoIcon} hasTitle={false} />
          <div className='tracking-content'>
            <p className='tracking-content-title'>Check your order status</p>
            <p>Enter your tracking number to check the status of your order</p>
            <div className='tracking-content-form'>
              <input
                type='text'
                placeholder='Enter Tracking Code eg. SB-252252525'
                value={value}
                onChange={(e) => handleChange(e)}
              />
              <button
                type='button'
                disabled={value.trim() === '' || loading}
                onClick={handleSubmit}
              >
                {loading ? <PulseLoader color='white' size={8} /> : 'Track'}
              </button>
            </div>
          </div>
          <TrackingShare
            name='share-bg tracking-share-card'
            url='https://www.shipbubble.com/'
            text='Share a WOW-worthy experience!'
          />
        </div>
        <TrackingFooter />
      </div>
    </>
  );
};

export default Tracking;
