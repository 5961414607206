import React from 'react';
import toast, {ToastBar, Toaster} from 'react-hot-toast';
import successToastIcon from '../assets/images/check.svg';
import closeToastIcon from '../assets/images/close-white.svg';
import errorToastIcon from '../assets/images/warn.svg';

const Toast = () => {
  return (
    <Toaster
      position='top-right'
      toastOptions={{
        success: {
          style: {
            background: '#067E21',
            border: '1px solid #067E21',
            color: 'white',
          },
          icon: (
            <img
              src={successToastIcon}
              className='toaster-icon'
              alt='success-icon'
            />
          ),
        },
        error: {
          style: {
            background: '#D74137',
            border: '1px solid #D74137',
            color: 'white',
          },
          icon: (
            <img
              src={errorToastIcon}
              className='toaster-icon'
              alt='error-icon'
            />
          ),
        },

        style: {
          boxShadow: 'none',
          minHeight: 44,
          height: '100%',
          minWidth: 100,
          maxWidth: 390,
          display: 'flex',
          justifyContent: 'space-between',
          padding: '8px 16px',
          borderRadius: 4,
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({icon, message}) => (
            <>
              <span className='status-toaster-icon'>{icon}</span>
              <span className='toaster-text'>{message}</span>
              {t.type !== 'loading' && (
                <span className='close-toaster-icon'>
                  <img
                    className='close-toaster-icon toaster-icon'
                    width={16}
                    height={16}
                    src={closeToastIcon}
                    alt='close-toaster'
                    onClick={() => toast.dismiss(t.id)}
                  />
                </span>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Toast;
