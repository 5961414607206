import React from 'react';
import shipbubbleLogo from '../assets/images/shipbubble-logo.svg';

const TrackingFooter = () => {
  return (
    <a
      className='tracking-footer'
      href='https://www.shipbubble.com/'
      target='_blank'
      rel='noopener noreferrer'
    >
      <span>Powered by</span>
      <img src={shipbubbleLogo} alt='shipbubble-logo' width={90} height={16} />
    </a>
  );
};

export default TrackingFooter;
