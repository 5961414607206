import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: null,
  error: null,
  tracking_data: null,
  branding_data: null,
  is_submitting: false,
};

const trackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRACKING_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_TRACKING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.GET_TRACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        tracking_data: action.payload,
        branding_data: action.payload.brand_config,
      };

    case actionTypes.SUBMIT_FEEDBACK_LOADING:
      return {
        ...state,
        is_submitting: true,
      };

    case actionTypes.SUBMIT_FEEDBACK_ERROR:
      return {
        ...state,
        is_submitting: false,
        error: action.payload,
      };

    case actionTypes.SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        is_submitting: false,
      };

    default:
      return state;
  }
};

export default trackingReducer;
