import copyIcon from '../assets/images/copy.svg';
import toast from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import React from 'react';

const TrackingHeader = ({
  title,
  hasTitle,
  copy,
  mode,
  data,
  brandingData,
}) => {
  const handleCopy = (type) => {
    toast.success(`Copied ${type} Successfully!`);
  };
  return (
    <>
      {brandingData !== null &&
        (brandingData?.menu_items?.menu_name_1 !== null ||
          brandingData?.menu_items?.menu_name_2 !== null ||
          brandingData?.menu_items?.menu_name_3 !== null ||
          brandingData?.brand_logo_url !== null) ? (
        <div className='tracking-container-header'>
          <div className='tracking-container-header-logo'>
            {brandingData !== null && hasTitle ? (
              <>
                {brandingData?.brand_logo_url !== null ? (
                  <img
                    className='tracking-container-header-image'
                      src={brandingData?.brand_logo_url}
                      alt='brand_logo'
                  />
                ) : (
                  <h1 className='tracking-container-header-title'>{title}</h1>
                )}
              </>
            ) : (
              <h1 className='tracking-container-header-title'>Shipbubble</h1>
              // <img src={image} alt='shipbubble-logo' />
            )}
          </div>

          {(brandingData?.menu_items?.menu_name_1 !== null ||
            brandingData?.menu_items?.menu_name_2 !== null ||
            brandingData?.menu_items?.menu_name_3 !== null) &&
            hasTitle && (
              <div className='tracking-container-header-nav'>
                <div className='tracking-container-header-nav-list'>
                  <div className='tracking-container-header-nav-item'>
                    <a
                      href={brandingData?.menu_items?.menu_link_url_1}
                      className='tracking-container-header-nav-item-text'
                    >
                      {brandingData?.menu_items?.menu_name_1}
                    </a>
                  </div>
                  {brandingData?.menu_items?.menu_name_2 !== null && (
                    <div className='tracking-container-header-nav-divider'></div>
                  )}
                  <div className='tracking-container-header-nav-item'>
                    <a
                      href={brandingData?.menu_items?.menu_link_url_2}
                      className='tracking-container-header-nav-item-text'
                    >
                      {brandingData?.menu_items?.menu_name_2}
                    </a>
                  </div>
                  {brandingData?.menu_items?.menu_name_3 !== null && (
                    <div className='tracking-container-header-nav-divider'></div>
                  )}
                  <div className='tracking-container-header-nav-item'>
                    <a
                      href={brandingData?.menu_items?.menu_link_url_3}
                      className='tracking-container-header-nav-item-text'
                    >
                      {brandingData?.menu_items?.menu_name_3}
                    </a>
                  </div>
                </div>
              </div>
            )}
        </div>
      ) : (
        <div className='tracking-default-container-header'>
          <p>{title}</p>
          <div className='tracking-container-header-socials'>
            {copy && (
              <CopyToClipboard
                text={data?.tracking_url}
                onCopy={() => handleCopy('Tracking Link')}
              >
                <span className='tracking-container-header-socials-link pointer'>
                  <img src={copyIcon} alt='copy-icon' />
                </span>
              </CopyToClipboard>
            )}
          </div>
        </div>
      )}

      {mode && mode !== 'production' && (
        <div className='red-banner'>
          This is a test shipment, no package is to be delivered
        </div>
      )}
    </>
  );
};

export default TrackingHeader;
