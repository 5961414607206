import shipbubbleIcon from '../assets/images/shipbubble-icon.svg';
import whatsappImage from '../assets/images/whatsapp-fill.svg';
import twitterImage from '../assets/images/twitter-fill.svg';
import forwardIcon from '../assets/images/chevron-forward.svg';
import closeIcon from '../assets/images/close-icon.svg';
import copyIcon from '../assets/images/copy.svg';
import {useState} from 'react';
import ReactModal from 'react-modal';
import familyIcon from '../assets/images/family.svg';
import facebookImage from '../assets/images/facebook-fill.svg';
import {WhatsappShareButton} from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import React from 'react';

const TrackingShare = ({url, text, name}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const handleShareModal = () => {
    setIsShareModalOpen(true);
  };

  const handleCopy = (type) => {
    toast.success(`Copied ${type} Successfully!`);
  };
  return (
    <>
      <div className={name}>
        <div className='tracking-share-card-text'>
          <p className='tracking-share-card-brand-text'>
            Share a WOW-worthy experience!
            {/* Share a WOW-worthy experience! <span>Ship with Shipbubble.</span> */}
          </p>
          <img
            src={shipbubbleIcon}
            alt='shipbubble-icon'
            width={32}
            height={32}
          />
        </div>
        <div onClick={handleShareModal}>
          <span className='share-text'>
            Tell your Friends
            <img src={forwardIcon} alt='forward-icon' width={18} height={18} />
          </span>
        </div>
      </div>
      <ReactModal
        isOpen={isShareModalOpen}
        onRequestClose={() => setIsShareModalOpen(false)}
        shouldCloseOnOverlayClick={true}
        destroyOnClose={true}
        overlayClassName='discard-overlay'
        className='discard-content'
        style={{
          overlay: {
            background: 'rgba(41, 45, 50, 0.28)',
            overflow: 'auto',
            minHeight: '100vh',
            padding: '24px 40px',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
          content: {
            background: 'transparent',
            border: 'none',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            minHeight: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        }}
      >
        <div className='share-container'>
          <div className='share-header rating-header'>
            <h1>Share the love</h1>
            <img
              className='pointer'
              src={closeIcon}
              onClick={() => setIsShareModalOpen(false)}
              alt='close-icon'
            />
          </div>
          <p className='share-subtitle'>
            Share with friends and help them discover the love
          </p>

          <div className='share-content'>
            <span>
              <img src={familyIcon} alt='family-icon' />
            </span>
          </div>

          <div className='share-flex'>
            <WhatsappShareButton
              title={text}
              url={url}
              className='whatsapp-button'
            >
              <img src={whatsappImage} alt='whatsapp-icon' />
              Share to Whatsapp
            </WhatsappShareButton>
            <button
              onClick={() =>
                window.open(
                  `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
                  '_blank'
                )
              }
            >
              <img src={twitterImage} alt='twitter-icon' />
              Share to Twitter
            </button>

            <button
              onClick={() =>
                window.open(
                  `https://www.facebook.com/sharer/sharer.php?t=${text}&u=${url}`,
                  '_blank'
                )
              }
            >
              <img src={facebookImage} alt='facebook-icon' />
              Share to Facebook
            </button>

            <CopyToClipboard text={url} onCopy={() => handleCopy(' ')}>
              <button>
                <img src={copyIcon} alt='copy-icon' />
                Copy Link
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default TrackingShare;
