import * as actionTypes from './actionTypes';
import {
  trackShippingOrder,
  submitFeedback,
} from '../../services/trackingService';
import toast from 'react-hot-toast';

export const trackOrder = (id, callback) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_TRACKING_START,
  });
  try {
    const response = await trackShippingOrder(id);
    dispatch({
      type: actionTypes.GET_TRACKING_SUCCESS,
      payload: response?.data,
    });
    callback(id, 'success');
  } catch (error) {
    callback(id, 'error', error);
    dispatch({
      type: actionTypes.GET_TRACKING_FAIL,
    });

    if (error?.response?.data?.errors) {
      toast.error(error?.response?.data?.errors[0]);
    }
  }
};

export const submitCustomerFeedback =
  (id, data, callback) => async (dispatch) => {
    dispatch({
      type: actionTypes.SUBMIT_FEEDBACK_LOADING,
    });
    try {
      const response = await submitFeedback(id, data);
      dispatch({
        type: actionTypes.SUBMIT_FEEDBACK_SUCCESS,
        payload: response?.message,
      });

      callback('success');
    } catch (error) {
      callback('error');
      toast.error(error?.response?.data?.errors[0]);
      dispatch({
        type: actionTypes.SUBMIT_FEEDBACK_ERROR,
        payload: error?.response?.data?.message,
      });
    }
  };
