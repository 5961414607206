import pendingIcon from '../assets/images/pending.svg';
import confirmedIcon from '../assets/images/confirmed.svg';
import pickedUpIcon from '../assets/images/picked-up.svg';
import inTransitIcon from '../assets/images/in-transit.svg';
import deliveredIcon from '../assets/images/delivered.svg';
import cancelledIcon from '../assets/images/cancelled.svg';
import {useState} from 'react';
import {useEffect} from 'react';
import moment from 'moment';
import React from 'react';

const TrackingProgressBar = ({ data, brandingData}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (data !== null && data !== undefined) {
      const arr = data?.filter((item) => item.datetime !== null);

      const latest = arr.sort((a, b) =>
        new moment(b.datetime) > moment(a.datetime)
          ? 1
          : moment(b.datetime) < moment(a.datetime)
          ? -1
          : 0
      );
      setValue(latest[0]);
    }
  }, [data]);

  const progressBarWidth = (status) => {
    switch (status) {
      case 'scheduled':
        return '15%';
      case 'pending':
        return '15%';
      case 'confirmed':
        return '25%';
      case 'picked_up':
        return '50%';
      case 'in_transit':
        return '75%';
      case 'cancelled':
        return '100%';
      case 'completed':
        return '100%';
      default:
        return '15%';
    }
  };

  return (
    <div className='tracking-progress'>
      <div
        className={
          value?.status === 'cancelled'
            ? 'tracking-progress-bar cancelled-progress-bar'
            : 'tracking-progress-bar'
        }
        style={{
          width: progressBarWidth(value?.status),
          backgroundColor:
            value?.status === 'cancelled'
              ? '#D83854'
              : brandingData?.brand_theme_color !== undefined &&
                brandingData?.brand_theme_color !== null &&
                brandingData?.brand_theme_color.trim().length !== 0
              ? brandingData?.brand_theme_color
              : '#067E21',
        }}
      >
        {(value?.status === 'pending' || value?.status === 'scheduled') && (
          <div
            className='tracking-progress-bar-circle'
            style={{
              backgroundColor:
                brandingData?.brand_theme_color !== undefined &&
                brandingData?.brand_theme_color !== null &&
                brandingData?.brand_theme_color.trim().length !== 0
                  ? brandingData?.brand_theme_color
                  : '#067E21',
            }}
          >
            <img src={pendingIcon} alt='pending-icon' />
          </div>
        )}
        {value?.status === 'confirmed' && (
          <div
            className='tracking-progress-bar-circle'
            style={{
              backgroundColor:
                brandingData?.brand_theme_color !== undefined &&
                brandingData?.brand_theme_color !== null &&
                brandingData?.brand_theme_color.trim().length !== 0
                  ? brandingData?.brand_theme_color
                  : '#067E21',
            }}
          >
            <img src={confirmedIcon} alt='confirmed-icon' />
          </div>
        )}
        {value?.status === 'picked_up' && (
          <div
            className='tracking-progress-bar-circle'
            style={{
              backgroundColor:
                brandingData?.brand_theme_color !== undefined &&
                brandingData?.brand_theme_color !== null &&
                brandingData?.brand_theme_color.trim().length !== 0
                  ? brandingData?.brand_theme_color
                  : '#067E21',
            }}
          >
            <img src={pickedUpIcon} alt='picked-up-icon' />
          </div>
        )}
        {value?.status === 'in_transit' && (
          <div
            className='tracking-progress-bar-circle'
            style={{
              backgroundColor:
                brandingData?.brand_theme_color !== undefined &&
                brandingData?.brand_theme_color !== null &&
                brandingData?.brand_theme_color.trim().length !== 0
                  ? brandingData?.brand_theme_color
                  : '#067E21',
            }}
          >
            <img src={inTransitIcon} alt='in-transit-icon' />
          </div>
        )}
        {value?.status === 'completed' && (
          <div
            className='tracking-progress-bar-circle'
            style={{
              backgroundColor:
                brandingData?.brand_theme_color !== undefined &&
                brandingData?.brand_theme_color !== null &&
                brandingData?.brand_theme_color.trim().length !== 0
                  ? brandingData?.brand_theme_color
                  : '#067E21',
            }}
          >
            <img src={deliveredIcon} alt='completed-icon' />
          </div>
        )}
        {value?.status === 'cancelled' && (
          <img src={cancelledIcon} alt='cancelled-icon' />
        )}
      </div>
    </div>
  );
};

export default TrackingProgressBar;
