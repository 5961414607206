import React from "react";
import infoIcon from "../assets/images/info-circle.svg";

const TrackingDelayedMessage = ({ message }) => {
  return (
    <div className="tracking-delayed-message-container">
      <div className="tracking-delayed-message-container-header">
        <div>
          <div>
            {" "}
            <img src={infoIcon} alt="info-icon" />
          </div>
          <p>{message}</p>
        </div>
      </div>
      {/* <div className="tracking-delayed-message-container-line"></div>
      <div className="tracking-delayed-message-container-text">
        <p>
          Important Update: Your order delivery has been delayed. We are
          actively working on it and expect to deliver it next week. Apologies
          for any inconvenience caused. Thank you for your understanding.
        </p>
      </div> */}
    </div>
  );
};

export default TrackingDelayedMessage;
